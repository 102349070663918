import React from 'react';
import '../Styles/main.css';
import { Card, CardContent, Typography } from '@mui/joy';

function Home() {
    return (
        <Card
         variant='soft'
         color='primary'
         size='lg'
         invertedColors
         sx={{
            margin: 2,
            height: 500
         }}
         >
          <CardContent
            sx={{
                alignItems: 'center',
            }}>
            <Typography
            level="h2"
            fontSize="lg"
            textColor="#000"
            >
            Always believe in Boran Supremacy
            </Typography>
          </CardContent>
        </Card>
    );
}

export default Home;