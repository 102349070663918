import logo from './Assets/logo.svg';
import './Styles/App.css';
import Main from './Components/main.js';

function App() {
  return (
    <Main>
    </Main>
  );
}

export default App;
