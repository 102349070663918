import React from 'react';
import '../Styles/main.css';
import { Sheet } from '@mui/joy';
import Sidebar from './sidebar'
import Home from './home';

const Main = ({ children }) => {
  return (
    <div className="main">
      {children}
      <Home></Home>
      <Sidebar></Sidebar>
    </div>
  );
};

export default Main;
